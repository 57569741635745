<template>
  <div>
    <v-btn v-for="exportReport in exportReports" :key="exportReport.name" icon dark color="main" :class="[cssClass]" :title="translations['report' + exportReport.name]" @click="() => openReportDialog(exportReport.name)">
      <v-icon :size="28" style="cursor:pointer">{{ exportReport.icon }}</v-icon>
    </v-btn>

    <!-- report export dialog -->
    <export-report-dialog v-if="isReportDialogOpen" :display="isReportDialogOpen" :title="reportDialogTitle" @close-dialog="() => (this.isReportDialogOpen = false)"></export-report-dialog>
  </div>
</template>

<script>
import { translations } from "@/utils/common";
import ExportReportDialog from "@/components/ExportReport/ExportReportDialog.vue";

export default {
  name: "ExportReportToolbar",

  props: {
    exportReports: {
      type: Array,
      required: true,
      default: () => {}
    },

    cssClass: {
      type: String,
      required: false,
      default: "mr-4"
    }
  },

  components: {
    ExportReportDialog
  },

  data: () => ({
    reportDialogTitle: "",
    isReportDialogOpen: false
  }),

  computed: {
    translations: () => translations
  },

  methods: {
    openReportDialog(title) {
      this.reportDialogTitle = title;
      this.isReportDialogOpen = true;
    }
  }
};
</script>
