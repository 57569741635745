<template>
  <v-dialog :value="display" @click:outside="$emit('close-dialog')" @keydown.esc="$emit('close-dialog')" max-width="750px">
    <v-card class="pa-2">
      <v-card-title class="text-h5">
        {{ translations["report" + title] }}
      </v-card-title>

      <v-card-text>
        <v-container>
          <section class="d-flex justify-space-between">
            <aside>
              <p class="ma-0 pa-2 f15">
                <b>{{ translations.date }} ({{ translations.from }})</b>
              </p>
              <v-date-picker show-current="false" v-model="dateFrom" locale="it"></v-date-picker>
            </aside>

            <aside>
              <p class="ma-0 pa-2 f15">
                <b>{{ translations.dateToProgram }} ({{ translations.to }})</b>
              </p>
              <v-date-picker show-current="false" v-model="dateTo" locale="it"></v-date-picker>
            </aside>
          </section>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-5 pt-0">
        <v-row class="ma-0" justify="center" align="end">
          <v-col class="pa-0 mr-2 " cols="auto" style="max-width: 120px">
            <v-select v-model="reportFormat" :items="formatsAvailable" :placeholder="translations.select" outlined dense hide-details max-width="150px"></v-select>
          </v-col>

          <v-col class="pa-0" cols="auto">
            <v-btn @click="printReport" :disabled="disableBtn" color="main" class="white--text px-8">{{ translations.generateReport }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import reportsApi from "@/utils/api/reportsApi";
import { translations } from "@/utils/common";
import moment from "moment";

export default {
  name: "ExportReportDialog",

  props: {
    display: {
      type: Boolean
    },

    title: {
      type: String
    }
  },

  data: () => ({
    dateFrom: moment(new Date())
      .subtract(1, "days")
      .format("YYYY-MM-DD"),

    dateTo: moment(new Date())
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    formatsAvailable: ["csv", "xls", "pdf"],
    reportFormat: "csv"
  }),

  computed: {
    translations: () => translations,

    disableBtn() {
      return new Date(this.dateFrom) > new Date(this.dateTo);
    }
  },

  methods: {
    printReport() {
      reportsApi.printReport(this.title, this.dateFrom, this.dateTo, this.reportFormat);
      this.$emit("close-dialog");
    }
  }
};
</script>
